import React from 'react'
import Banner from './Banner'
import PageIntro from './PageIntro'
import ServicesDescription from './ServicesDescription'
import CTA from '../Contact Form/CTA'


function HomeHealth() {
  return (
    <div>
        <Banner />
        < PageIntro />
        < ServicesDescription />
        < CTA />
    </div>
  )
}

export default HomeHealth